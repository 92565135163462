import * as actionTypes from "./actionTypes";
import { myFirebase } from "../../firebase/firebase";
import { markers } from '../../data/markers';

export const OUT_OF_UPPER_RANGE = 9999999999
export const OUT_OF_LOWER_RANGE = -9999999999
export const TNP = -9999999998

const getGender = (gender) => {
    const lower_gender = gender.toLowerCase();
    if (lower_gender === 'f' || lower_gender === 'female') {
        return 'female';
    } else {
        return 'male'
    }
}

const requestSummary = () => {
    return {
        type: actionTypes.BLOOD_SUMMARY_REQUEST
    };
};

const getStatus = (range, report, gender) => {
    if (isNaN(report.value)) {
      return report.status
    }
    if (report.value === OUT_OF_LOWER_RANGE) {
      return 'OUT_OF_LOWER_RANGE'
    }
  
    if (report.value === OUT_OF_UPPER_RANGE) {
      return 'OUT_OF_UPPER_RANGE'
    }
  
    if (report.value === TNP) {
      return 'TNP'
    }
  
    // Calculate status based on value and range
    const value = report.value
    if (value < range[gender].normal_min) {
      if (value < range[gender].border_min) {
        return 'RISK'
      }
      return 'BORDERLINE'
    } else if (value > range[gender].normal_max) {
      if (value > range[gender].border_max) {
        return 'RISK'
      }
      return 'BORDERLINE'
    } else {
      return 'NORMAL'
    }
}

const constructHistory = (history, gender) => {
    for (const [marker, value] of Object.entries(history)) {
      for (const [barcode, report] of Object.entries(value)) {
        if (markers[marker] == undefined) {
          console.log('Marker not found: ' + marker)
          continue
        }
        history[marker][barcode]['status'] = getStatus(
          markers[marker].range,
          report,
          gender
        )
      }
    }
    return history
}

const reconstructSummary = (history, exceptions, gender) => {
    const transform = {}
    for (const [marker, value] of Object.entries(history)) {
      for (const [barcode, report] of Object.entries(value)) {
        if (transform[barcode] == undefined) {
          transform[barcode] = {}
        }
        transform[barcode][marker] = report
      }
    }
  
    for (const [barcode, value] of Object.entries(exceptions)) {
      for (const [key_, report] of Object.entries(value)) {
        if (transform[report.id] == undefined) {
          transform[report.id] = {}
        }
        transform[report.id][report.marker_name] = report
      }
    }
  
    const return_data = {}
    for (const [barcode, value] of Object.entries(transform)) {
      for (const [marker, report] of Object.entries(value)) {
        if (return_data[barcode] == undefined) {
          return_data[barcode] = {
            borderline: [],
            date: report.date,
            normal: [],
            otr: [],
            risk: [],
            tnp: [],
            id: barcode
          }
        }
        if (markers[marker] == undefined) {
          console.log('Marker not found: ' + marker)
          continue
        }
        const markerRange = markers[marker].range
        const status = getStatus(markerRange, report, gender)
        switch (status) {
          case 'OUT_OF_LOWER_RANGE':
            return_data[barcode]['otr'].push(marker)
            break
          case 'OUT_OF_UPPER_RANGE':
            return_data[barcode]['otr'].push(marker)
            break
          case 'TNP':
            return_data[barcode]['tnp'].push(marker)
            break
          case 'RISK':
            return_data[barcode]['risk'].push(marker)
            break
          case 'BORDERLINE':
            return_data[barcode]['borderline'].push(marker)
            break
          case 'NORMAL':
            return_data[barcode]['normal'].push(marker)
            break
          default:
            break
        }
      }
    }
  
    return return_data
}

const receiveSummary = (summary, uid) => {
  // if the summary is empty that means there is no data in report
  if (!summary) {
    return {
      type: actionTypes.BLOOD_SUMMARY_SUCCESS,
      summary: [],
      trends: [],
      exceptions: [],
      uid: uid
    }
  }

  const updated_data = []
  // still do some validation here in case backend has a bug to send the data back.
  const gender =
    summary.metadata == undefined || summary.metadata.gender == undefined
      ? 'male'
      : getGender(summary.metadata.gender)
  const history =
    summary.history == undefined
      ? []
      : constructHistory(summary.history, gender)
  const sorted_exceptions =
    summary.exceptions == undefined
      ? []
      : Object.values(summary.exceptions).sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        )

  for (let [key_, value_] of Object.entries(history)) {
    if (markers[key_]) {
      const rangeToUse =
        markers[key_].range[gender] ??
        markers[key_].range.male ??
        markers[key_].range.female
      // TODO: add barcode here which is the key_
      const sort_data = Object.keys(value_).map((item) => {
        return {
          ...value_[item],
          report_id: item
        }
      })
      const sorted_data = Object.values(sort_data).sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      )
      const latestValue = sorted_data[sorted_data.length - 1].value
      let latest_status = 'TNP' // Default status

      if (latestValue === OUT_OF_LOWER_RANGE) {
        latest_status = 'OUT_OF_LOWER_RANGE'
      } else if (latestValue === OUT_OF_UPPER_RANGE) {
        latest_status = 'OUT_OF_UPPER_RANGE'
      } else if (latestValue === TNP) {
        latest_status = 'TNP'
      } else if (latestValue < rangeToUse.normal_min) {
        latest_status =
          latestValue < rangeToUse.border_min ? 'RISK' : 'BORDERLINE'
      } else if (latestValue > rangeToUse.normal_max) {
        latest_status =
          latestValue > rangeToUse.border_max ? 'RISK' : 'BORDERLINE'
      } else if (isNaN(latestValue)) {
        latest_status = sorted_data[sorted_data.length - 1].status
      } else {
        latest_status = 'NORMAL'
      }
      let delta = ''
      if (sorted_data.length === 1) {
        delta = '0'
      } else {
        const prev = sorted_data[sorted_data.length - 2].value
        const current = sorted_data[sorted_data.length - 1].value
        delta = (((current - prev) / prev) * 100).toFixed(0)
      }

      updated_data.push({
        ...markers[key_],
        range: rangeToUse,
        data: sorted_data,
        latest_status,
        delta: delta,
        key: key_,
        id: Object.keys(value_)
      })
    }
  }

  const sorted_summary = Object.values(
    reconstructSummary(history, sorted_exceptions, gender)
  ).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

  const mappedSortedExceptions = sorted_exceptions.map((exception) => {
    return exception.map((item) => {
      return {
        ...item,
        gender
      }
    })
  })
  return {
    type: actionTypes.BLOOD_SUMMARY_SUCCESS,
    summary: sorted_summary,
    trends: updated_data,
    exceptions: mappedSortedExceptions,
    uid: uid
  }
}

const summaryError = error => {
    return {
        type: actionTypes.BLOOD_SUMMARY_FAILURE,
        error: error
    };
};

const requestSummaryUpdate = () => {
    return {
        type: actionTypes.BLOOD_SUMMARY_UPDATE_REQUEST
    };
};

// TODO: need to update later
const receiveSummaryUpdate = () => {
    return {
        type: actionTypes.BLOOD_SUMMARY_UPDATE_SUCCESS,
    };
};

const summaryUpdateError = error => {
    return {
        type: actionTypes.BLOOD_SUMMARY_UPDATE_FAILURE,
        error: error
    };
}

export const getBloodSummary = (uid) => dispatch => {
    dispatch(requestSummary());
    myFirebase.firestore().collection('blood_summary').doc(uid).get().then(doc => {
        if (doc.exists) {
            dispatch(receiveSummary(doc.data(), uid))
        } else {
            console.log("No data presented for " + uid);
            dispatch(receiveSummary(null, uid));
        }
    }).catch(error => {
        console.log(error);
        dispatch(summaryError(error.message))
    })
}

export const saveBloodSummary = (summary, uid) => dispatch => {
        if (summary) {
            dispatch(receiveSummary(summary?.data?.data?.summary, uid))
        } else {
            console.log("No data presented");
            dispatch(receiveSummary(null, uid));
        }
}

export const updateBloodSummaryNote = (uid, marker_key, barcode, note) => dispatch => {
    dispatch(requestSummaryUpdate());
    let usersUpdate = {};
    usersUpdate[`history.${marker_key}.${barcode}.note`] = note;
    myFirebase.firestore().collection('blood_summary').doc(uid).update(usersUpdate).then(() => {
        dispatch(receiveSummaryUpdate()); 
        dispatch(getBloodSummary(uid)); 
    }
    ).catch(error => {
        dispatch(summaryUpdateError(error.message))
    });
}